import React from 'react';
import PropTypes from 'prop-types';
import MultipleChoice from './MultipleChoice';
import Checkbox from './Checkbox';
import Text from './Text';
import Email from './Email';
import Range from './Range';
import ContactInformation from './ContactInformation';

import stylesheet from './Question.css';

const Question = ({ handleEditResponse, question, ...props }) => {
  let input;

  switch (question.response) {
    case 'pickOne':
      input = (
        <MultipleChoice
          question={question}
          handleEditResponse={handleEditResponse}
          {...props}
        />
      );
      break;
    case 'pickMany':
      input = (
        <Checkbox
          question={question}
          handleEditResponse={handleEditResponse}
          {...props}
        />
      );
      break;
    case 'text':
      input = (
        <Text
          question={question}
          handleEditResponse={handleEditResponse}
          {...props}
        />
      );
      break;
    case 'range':
      input = (
        <Range
          question={question}
          handleEditResponse={handleEditResponse}
          {...props}
        />
      );
      break;
    case 'email':
      input = (
        <Email
          question={question}
          handleEditResponse={handleEditResponse}
          {...props}
        />
      );
      break;
    case 'contactInformation':
      input = (
        <ContactInformation
          question={question}
          handleEditResponse={handleEditResponse}
          {...props}
        />
      );
      break;
    default:
      input = (
        <Text
          question={question}
          handleEditResponse={handleEditResponse}
          {...props}
        />
      );
  }

  return <div className={stylesheet.questionContainer}>{input}</div>;
};

Question.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
};

export default Question;
