import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import countries from '@mc/editing/blocks/runtimes/countries';
import InputText from '@mc/components/Input/InputText';
import { StackLayout } from '@mc/wink';
import QuestionLabel from '../QuestionLabel';

import questionStylesheet from '../Question.css';

const Address = ({ question, onChange, value, handleEditResponse }) => {
  const DEFAULT_COUNTRY = 164; // USA
  const DEFAULT_COUNTRY_LABEL = 'USA';
  const MAX_CHAR_LENGTH = 255;

  const [address, setAddress] = useState({
    addr1: value?.object?.addr1 ? value.object.addr1 : '',
    addr2: value?.object?.addr2 ? value.object.addr2 : '',
    city: value?.object?.city ? value.object.city : '',
    country: value?.object?.country ? value.object.country : DEFAULT_COUNTRY,
    countryText: value?.object?.countryText
      ? value.object.country
      : DEFAULT_COUNTRY_LABEL,
    state: value?.object?.state ? value.object.state : '',
    zip: value?.object?.zip ? value.object.zip : '',
  });

  const onAddressChange = (result, addressType) => {
    const newAddressObj = { ...address };
    newAddressObj[addressType] = result;
    setAddress(newAddressObj);
    onChange({ object: newAddressObj });
  };

  const onAddressChangeCountry = async (countryId, countryText) => {
    const newAddressObj = { ...address };
    newAddressObj.country = parseInt(countryId, 10);
    newAddressObj.countryText = countryText;
    setAddress(newAddressObj);
    await onChange({ object: newAddressObj });
    handleEditResponse();
  };

  // Resolve rendering issue for country label
  // Examaple: 'Turks &amp; Caicos Islands' should be 'Turks & Caicos Islands'
  const renderCountryLabel = (label) => {
    if (!label) {
      return '';
    }
    return label.replace('&amp;', '&');
  };

  return (
    <div className={questionStylesheet.question}>
      <div className={questionStylesheet.inputWrapper}>
        <StackLayout>
          <InputText
            type="text"
            onChange={(result) => {
              onAddressChange(result, 'addr1');
            }}
            label={<QuestionLabel question={question} />}
            placeholder="Street Address"
            value={address.addr1}
            maxLength={MAX_CHAR_LENGTH}
            renderCharacterCount={() => {}}
            onBlur={handleEditResponse}
          />
          <InputText
            type="text"
            onChange={(result) => {
              onAddressChange(result, 'addr2');
            }}
            label={<></>}
            isLabelVisible={false}
            placeholder="Address Line 2"
            value={address.addr2}
            maxLength={MAX_CHAR_LENGTH}
            onBlur={handleEditResponse}
          />
          <InputText
            type="text"
            onChange={(result) => {
              onAddressChange(result, 'city');
            }}
            label={<></>}
            isLabelVisible={false}
            placeholder="City"
            value={address.city}
            maxLength={MAX_CHAR_LENGTH}
            onBlur={handleEditResponse}
          />
          <InputText
            type="text"
            onChange={(result) => {
              onAddressChange(result, 'state');
            }}
            label={<></>}
            isLabelVisible={false}
            placeholder="State/Province/Region"
            value={address.state}
            maxLength={MAX_CHAR_LENGTH}
            onBlur={handleEditResponse}
          />
          <InputText
            type="text"
            onChange={(result) => {
              onAddressChange(result, 'zip');
            }}
            label={<></>}
            isLabelVisible={false}
            placeholder="Postal/Zip"
            value={address.zip}
            maxLength={MAX_CHAR_LENGTH}
            onBlur={handleEditResponse}
          />
          <select
            className={questionStylesheet.selectQuestion}
            onChange={(result) => {
              const label = result.target.selectedOptions[0].innerText;
              onAddressChangeCountry(result.target.value, label);
            }}
            placeholder="Country"
            value={address.country || DEFAULT_COUNTRY}
          >
            {countries.map((country) => {
              const label = renderCountryLabel(country.label);
              return (
                <option key={label} value={country.value} aria-label={label}>
                  {label}
                </option>
              );
            })}
          </select>
        </StackLayout>
      </div>
    </div>
  );
};

Address.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  value: PropTypes.shape({
    object: PropTypes.shape({
      addr1: PropTypes.string,
      addr2: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.number,
      countryText: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
    }),
  }),
};

export default Address;
