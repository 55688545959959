import React from 'react';
import PropTypes from 'prop-types';
import stylesheet from './SurveyHeader.css';

function Logo({ survey }) {
  if (survey.hostedStyles.assetUrl) {
    return (
      <div className={stylesheet.logoContainer}>
        <img
          className={stylesheet.logo}
          src={survey.hostedStyles.assetUrl}
          alt="Survey"
        />
      </div>
    );
  }

  if (survey.hostedStyles.logoEnabled) {
    return (
      <div className={stylesheet.logoContainer}>
        <img
          className={stylesheet.logo}
          src={survey.hostedStyles.logoUrl}
          alt="Logo"
        />
      </div>
    );
  }

  return null;
}

Logo.propTypes = {
  survey: PropTypes.object.isRequired,
};

function SurveyHeader({ survey }) {
  const hostedStyles = survey.hostedStyles;
  const description = hostedStyles.description;
  const hasAsset = hostedStyles.logoEnabled || survey.hostedStyles.assetUrl;

  if (hasAsset || description) {
    return (
      <div className={stylesheet.surveyIntro}>
        <Logo survey={survey} />
        {description && (
          <div
            // for now we assume center, but this will become configureable
            className={stylesheet.surveyDescription}
            // Custom HTML by user on the app. It's stripped server-side
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    );
  }

  return null;
}

SurveyHeader.propTypes = {
  survey: PropTypes.object.isRequired,
};

export default SurveyHeader;
