import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputText from '@mc/components/Input/InputText';
import QuestionLabel from '../../QuestionLabel';

import questionStylesheet from '../../Question.css';
import stylesheet from './PhoneUS.css';

const PhoneUS = ({ onChange, question, value, handleEditResponse }) => {
  const [phone, setPhone] = useState({
    area: value?.object?.area ? value.object.area : '',
    detail1: value?.object?.detail1 ? value.object.detail1 : '',
    detail2: value?.object?.detail2 ? value.object.detail2 : '',
  });

  const onPhoneChange = (result, phoneType) => {
    const newPhoneObj = { ...phone };
    newPhoneObj[phoneType] = result;
    setPhone(newPhoneObj);
    onChange({ object: newPhoneObj });
  };

  return (
    <div className="question text">
      <div className={classNames('input-wrapper')}>
        <QuestionLabel
          className={questionStylesheet.questionLabel}
          question={question}
        />
        <InputText
          className={stylesheet.subfields}
          isLabelVisible={false}
          label={<></>}
          maxLength={3}
          onChange={(result) => {
            onPhoneChange(result, 'area');
          }}
          pattern="[0-9]*"
          placeholder="###"
          renderCharacterCount={() => {}}
          size={3}
          type="text"
          value={phone.area}
          onBlur={handleEditResponse}
        />
        {' ) '}
        <InputText
          className={stylesheet.subfields}
          isLabelVisible={false}
          label={<></>}
          maxLength={3}
          onChange={(result) => {
            onPhoneChange(result, 'detail1');
          }}
          pattern="[0-9]*"
          placeholder="###"
          renderCharacterCount={() => {}}
          size={3}
          type="text"
          value={phone.detail1}
          onBlur={handleEditResponse}
        />
        {' - '}
        <InputText
          className={stylesheet.subfields}
          isLabelVisible={false}
          label={<></>}
          maxLength={4}
          onChange={(result) => {
            onPhoneChange(result, 'detail2');
          }}
          pattern="[0-9]*"
          placeholder="####"
          renderCharacterCount={() => {}}
          size={4}
          type="text"
          value={phone.detail2}
          onBlur={handleEditResponse}
        />
      </div>
    </div>
  );
};

PhoneUS.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  value: PropTypes.shape({
    object: {
      area: PropTypes.string,
      detail1: PropTypes.string,
      detail2: PropTypes.string,
    },
  }),
};

export default PhoneUS;
