import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Form, { SubmitButton } from '@mc/components/Form';
import stylesheet from './Form.css';
import SectionFormFieldList from './SectionFormFieldList';

const SurveyForm = ({ handleEditResponse, survey, onSubmit, responseData }) => {
  const hostedStyles = survey.hostedStyles;

  const submitBtnStyleOverrides = {};
  if (hostedStyles.buttonColor) {
    submitBtnStyleOverrides.background = hostedStyles.buttonColor;
  }

  if (hostedStyles.buttonTextColor) {
    submitBtnStyleOverrides.color = hostedStyles.buttonTextColor;
  }

  const submitText = hostedStyles.submitButtonText
    ? hostedStyles.submitButtonText
    : 'Submit';

  const ref = useRef();
  return (
    <div className={stylesheet.formContainer}>
      <Form
        onSubmit={() => onSubmit(ref)}
        scrollToErrors
        ref={ref}
        initialValues={responseData}
      >
        <SectionFormFieldList
          survey={survey}
          handleEditResponse={() => handleEditResponse(ref)}
        />
        <div className={stylesheet.submitContainer}>
          <SubmitButton type="primary" style={submitBtnStyleOverrides}>
            {submitText}
          </SubmitButton>
        </div>
      </Form>
    </div>
  );
};

SurveyForm.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  responseData: PropTypes.object,
  survey: PropTypes.object.isRequired,
};

export default SurveyForm;
