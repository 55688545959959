import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import InputText from '@mc/components/Input/InputText';
import Checkbox from '@mc/components/Input/Checkbox';
import QuestionLabel from '../QuestionLabel';
import stylesheet from './Email.css';

const DEFAULT_SUBSCRIBE_LABEL = 'Subscribe me to your newsletter';

const Email = ({ question, onChange, value, handleEditResponse }) => {
  let inquiry = question.inquiry;
  if (question.isRequired) {
    inquiry += ' *';
  }

  value = value ? value : {};

  const subscribe = value.subscribe ? value.subscribe : false;
  const email = value.freeform ? value.freeform : '';

  let subscribeCheckboxLabel = question.properties.subscribeCheckboxLabel;
  if (!subscribeCheckboxLabel || !subscribeCheckboxLabel.trim()) {
    subscribeCheckboxLabel = DEFAULT_SUBSCRIBE_LABEL;
  }

  const handleEmailChange = (result) => {
    onChange({ freeform: result, subscribe: subscribe });
  };

  const handleSubscribeChange = async (checked) => {
    await onChange({ freeform: email, subscribe: checked });
    handleEditResponse();
  };

  return (
    <div className="question email">
      <div className="input-wrapper">
        <InputText
          type="email"
          value={email}
          onChange={handleEmailChange}
          label={<QuestionLabel question={question} />}
          placeholder="email@example.com"
          onBlur={handleEditResponse}
        />
        {question.properties.subscribeCheckboxEnabled && (
          <div className={stylesheet.checkboxContainer}>
            <Checkbox
              value={subscribe}
              onChange={handleSubscribeChange}
              label={subscribeCheckboxLabel}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Email.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  value: PropTypes.shape({
    freeform: PropTypes.string,
    subscribe: PropTypes.bool,
  }),
};

export default Email;
