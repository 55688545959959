import React from 'react';
import PropTypes from 'prop-types';
import styles from './SectionVideo.css';
import { generateVideoUrl } from './videoUrlHelpers';

export const SectionVideo = ({ section, invalidImageUrl }) => {
  const src = generateVideoUrl(
    section.options.video_id,
    section.options.video_type,
  );
  return (
    <div className={styles.uploadVideo}>
      {(src && (
        <iframe
          allowFullScreen="on"
          title="Introduction Video"
          src={src}
          frameBorder="0"
        />
      )) ||
        (invalidImageUrl && (
          <img
            className={styles.thumbnail}
            alt="Invalid Video URL"
            src={invalidImageUrl}
          />
        )) || <span>Video URL is empty. </span>}
    </div>
  );
};

SectionVideo.propTypes = {
  invalidImageUrl: PropTypes.string,
  section: PropTypes.object.isRequired,
};

export default SectionVideo;
