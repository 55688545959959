import React from 'react';
import PropTypes from 'prop-types';
import SurveyTextarea from '../SurveyTextArea';
import { MAX_TEXT_LIMIT } from '../constants';
import stylesheet from './Other.css';

const Other = ({ handleEditResponse, onChange, value }) => {
  return (
    <div className={stylesheet.wrapper}>
      <SurveyTextarea
        value={value}
        onChange={onChange}
        shouldGrowWithContent
        minRows={3}
        maxLength={MAX_TEXT_LIMIT}
        handleEditResponse={handleEditResponse}
      />
    </div>
  );
};

Other.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Other;
