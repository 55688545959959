import React from 'react';
import PropTypes from 'prop-types';
import { StackLayout } from '@mc/wink';
import QuestionFormField from '../QuestionFormField';
import SectionText from '../SectionText';
import { SectionVideo } from '../SectionVideo';
import { SectionImage } from '../SectionImage';
import stylesheet from './SectionFormFieldList.css';

const SectionFormFieldList = React.forwardRef(function SectionFormFieldList(
  { handleEditResponse, survey },
  ref,
) {
  const SUBTYPE = {
    TEXT: 'text',
    VIDEO: 'video',
    IMAGE: 'image',
  };
  return (
    <ol
      ref={ref}
      className={stylesheet.sectionsList}
      data-testid="SectionFormFieldList"
    >
      {survey.sections.map((section) => {
        return (
          <li key={section.options.origin_id}>
            {(section.type === 'introduction' || section.type === 'context') &&
              ((section.options.sub_type === SUBTYPE.TEXT && (
                <div className={stylesheet.textWrapper}>
                  <SectionText section={section} />
                </div>
              )) ||
                (section.options.sub_type === SUBTYPE.VIDEO && (
                  <div className={stylesheet.videoIntroduction}>
                    <SectionVideo section={section} />
                  </div>
                )) ||
                (section.options.sub_type === SUBTYPE.IMAGE && (
                  <StackLayout alignment="center" gap={1}>
                    <SectionText section={section} />
                    <div className={stylesheet.imageWrapper}>
                      <SectionImage section={section} />
                    </div>
                  </StackLayout>
                )))}
            {section.type === 'question' && (
              <QuestionFormField
                question={section.question}
                handleEditResponse={handleEditResponse}
              />
            )}
          </li>
        );
      })}
    </ol>
  );
});

SectionFormFieldList.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  survey: PropTypes.object.isRequired,
};

export default SectionFormFieldList;
