import React from 'react';
import PropTypes from 'prop-types';
import QuestionLabel from '../QuestionLabel';

import questionStylesheet from '../Question.css';

const Dropdown = ({ question, onChange, value, handleEditResponse }) => {
  return (
    <div className={questionStylesheet.question}>
      <div className={questionStylesheet.inputWrapper}>
        <QuestionLabel
          question={question}
          className={questionStylesheet.questionLabel}
        />
        <select
          className={questionStylesheet.selectQuestion}
          value={value ? value.key : ''}
          onChange={async (result) => {
            await onChange({ key: result.target.value });
            handleEditResponse();
          }}
        >
          <option key="" value="" aria-label="Select...">
            Select...
          </option>
          {question.response === 'contactInformation' &&
            question.merge_field.choices.map((option) => {
              return (
                <option key={option} value={option} aria-label={option}>
                  {option}
                </option>
              );
            })}
        </select>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  value: PropTypes.shape({
    key: PropTypes.string,
  }),
};

export default Dropdown;
