import React from 'react';
import PropTypes from 'prop-types';
import { SURVEY_CLOSED_MESSAGE } from '../../shared/helpers/utils';
import stylesheet from './Messages.css';

function Message({ text }) {
  return (
    <div className={stylesheet.messageContainer}>
      <div
        className={stylesheet.successMessageContainer}
        // Custom HTML by user on the app. It's stripped server-side
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
}

Message.propTypes = {
  text: PropTypes.string,
};

function CompletionMessage({ survey }) {
  const confirmationMessage = survey.hostedStyles.confirmationMessage
    ? survey.hostedStyles.confirmationMessage
    : 'Thank you! We appreciate your feedback.'; //TODO this will be tricky to translate because it's used in live page too. can't use hooks or tt because they rely on flag data

  return <Message text={confirmationMessage} />;
}

CompletionMessage.propTypes = {
  survey: PropTypes.object.isRequired,
};

function ClosedMessage({ survey }) {
  const closedMessage = survey.hostedStyles.closedMessage
    ? survey.hostedStyles.closedMessage
    : SURVEY_CLOSED_MESSAGE; //TODO this will be tricky to translate because it's used in live page too. can't use hooks or tt because they rely on flag data
  return <Message text={closedMessage} />;
}

ClosedMessage.propTypes = {
  survey: PropTypes.object.isRequired,
};

export { CompletionMessage, ClosedMessage };
