import React from 'react';
import PropTypes from 'prop-types';
import Other from '../Common/Other';
import questionStylesheet from '../Question.css';
import QuestionLabel from '../QuestionLabel';
import { OTHER_VALUE } from '../constants';
import MultipleChoiceOption from './MultipleChoiceOption';

const MultipleChoice = ({ handleEditResponse, question, onChange, value }) => {
  value = value ? value : {};

  const otherOptionLabel = question.properties.otherLabel || 'Other';
  const optionValue = value.key !== undefined ? value.key : '';
  const otherTextValue = value.freeform ? value.freeform : '';

  const handleChange = async (result) => {
    const changeValue = { key: result };
    if (result === OTHER_VALUE) {
      changeValue.freeform = otherTextValue;
    }
    await onChange(changeValue);
    handleEditResponse();
  };

  const handleOtherTextChange = async (result) => {
    await onChange({ key: optionValue, freeform: result });
    handleEditResponse();
  };

  return (
    <div className={questionStylesheet.question}>
      <QuestionLabel question={question} />
      <div className="responses">
        <fieldset>
          {question.response === 'contactInformation' &&
            question.merge_field.choices.map((option) => {
              return (
                <MultipleChoiceOption
                  label={option}
                  value={option}
                  checked={optionValue === option}
                  onChange={handleChange}
                  key={option}
                  question={question}
                />
              );
            })}
          {question.response !== 'contactInformation' &&
            question.properties.options.map((option) => {
              return (
                <MultipleChoiceOption
                  label={option.label}
                  value={option.value}
                  checked={optionValue === option.value}
                  onChange={handleChange}
                  key={option.value}
                  question={question}
                />
              );
            })}
          {question.properties.hasOther && (
            <>
              <MultipleChoiceOption
                label={otherOptionLabel}
                value={OTHER_VALUE}
                checked={OTHER_VALUE === optionValue}
                onChange={handleChange}
                key={OTHER_VALUE}
                question={question}
              />
              {optionValue === OTHER_VALUE && (
                <Other
                  value={otherTextValue}
                  onChange={handleOtherTextChange}
                  handleEditResponse={handleEditResponse}
                />
              )}
            </>
          )}
        </fieldset>
      </div>
    </div>
  );
};

MultipleChoice.propTypes = {
  handleEditResponse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  value: PropTypes.shape({
    freeform: PropTypes.string,
    key: PropTypes.string,
  }),
};

export default MultipleChoice;
