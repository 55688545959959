import React from 'react';
import PropTypes from 'prop-types';
import stylesheet from './SectionImage.css';

export const SectionImage = ({ section }) => {
  const { options } = section;
  return (
    <div>
      <img
        data-testid={`${options.origin_id}-section-image`}
        className={stylesheet.sectionImage}
        src={
          options.image_url ||
          'https://cdn-images.mailchimp.com/image-placeholder.png'
        }
        alt={
          options.image_url
            ? options.image_alt_text || 'content'
            : 'None uploaded, uploaded image will appear here'
        }
      />
    </div>
  );
};

SectionImage.propTypes = {
  section: PropTypes.object.isRequired,
};

export default SectionImage;
