import React from 'react';
import PropTypes from 'prop-types';
import useId from '@mc/hooks/useId';
import stylesheet from './Checkbox.css';

const CheckboxOption = ({ label, value, checked, onChange }) => {
  const id = useId();

  return (
    <div className="input-wrapper">
      <label className={stylesheet.label} htmlFor={id}>
        <input
          className={stylesheet.checkboxInput}
          type="checkbox"
          id={id}
          checked={checked}
          value={value}
          onChange={(event) => onChange(event.target.checked, value)}
        />
        <span className={stylesheet.labelText}>{label}</span>
      </label>
    </div>
  );
};

CheckboxOption.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default CheckboxOption;
